<template>
  <div class="container">
    <div class="ring" :style="{ transform: 'rotateX(' + rotateX + 'deg' + ')' }">
      <div
        v-for="(picture, index) in thumbs"
        :key="index"
        class="thumb"
        :style="{
          '--ringspin-rotateX': divisionDegré * index + 'deg',
          '--ringspin-translateZ': 90 + 'px',
          'background-image': 'url(' + picture + ')'
        }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      running: false,
      rotateX: 0,
      frame: null,
      turn: 600,
      multiplier: 0,
      divisionDegré: 60,
      thumbs: [
        require("@/assets/img/machine_slots/1.png"),
        require("@/assets/img/machine_slots/2.png"),
        require("@/assets/img/machine_slots/3.png"),
        require("@/assets/img/machine_slots/4.png"),
        require("@/assets/img/machine_slots/5.png"),
        require("@/assets/img/machine_slots/6.png"),
      ],
    };
  },
  props: {
    vDice: {
      type: Number,
      default: 0,
    },
    vExec: {
      type: Boolean,
      default: false,
    },
    vDelay: {
      type: Number,
      defaut: 0,
    },
  },
  watch: {
    execSpin() {
      // j'ai besoin d'une ecoute permanente sur l'attribut execSpin (computed)
      // afin d'avoir un declencheur quand j'execute le vDice
      // j'avoue avoir passé pas mal de temps et essayer plein d'alternatives
      // celle ci reste la plus simple selon moi et surtout mes connaissances :)
    },
  },
  computed: {
    execSpin() {
      if (this.vExec) {
        this.ready(); // afin d'eviter d'executer la roue au démarrage du script
        if (this.$parent.execSpin && !this.running) {
          this.$parent.busyMachine++;
          this.run();
        }
      }

      return this.vExec;
    },
    getRotationXbySymbol() {
      let rotateX = 360;

      switch (this.vDice) {
        case 1:
          rotateX = 360; //cerise
          break;
        case 2:
          rotateX = 300; // 7
          break;
        case 3:
          rotateX = 240; // citron
          break;
        case 4:
          rotateX = 180; // orange
          break;
        case 5:
          rotateX = 120; // arlequin
          break;
        case 6:
          rotateX = 60; // diamant
          break;
      }

      return rotateX;
    },
  },
  beforeDestroy() {
    this.halt();
  },
  methods: {
    ready() {
      /*
        avec cette methode requestAnimationFrame est pret à démarrer
      */
      this.multiplier = 20;
      this.turn = 0;
    },
    halt() {
      /*
        avec cette methode le cycle est terminé
        pour economiser des boucles sans fin de requestAnimationFrame
        il avertit son parent de la fin de sa mission et stop la boucle
      **/
      this.multiplier = 0;
      this.turn = 600;
      this.$parent.execSpin = false; // je réinitialise l'attribut du composant parent
      this.$parent.busyMachine--; // je réinitialise l'attribut du composant parent
      cancelAnimationFrame(this.frame);
    },
    run() {
      // start run info
      this.running = true;

      const exec = () => {
        // si ca depasse 360 degré alors reset a 0
        if (this.rotateX > 360) this.rotateX = 0;

        // permet la rotation du cylindre
        if (this.turn < 600) {
          if (this.rotateX <= 360) {
            this.rotateX = this.rotateX + 1 * this.multiplier;
          }
          this.turn++;
        }

        //verifie si le cycle est terminé
        if (this.turn > 100 + this.vDelay && this.multiplier <= 6) {
          if (this.rotateX === this.getRotationXbySymbol) {
            this.running = false;
            this.halt();
          }
        } else if (this.turn > 75 + this.vDelay && this.multiplier > 6) {
          this.multiplier--;
        } else if (this.turn > 50 + this.vDelay && this.multiplier > 12) {
          this.multiplier--;
        }

        // loop fonction exec()
        if (this.running) this.frame = requestAnimationFrame(exec);
      };
      exec();
    },
  },
};
</script>

<style scoped>
:root {
  --ringspin-rotateX: 0deg;
  --ringspin-translateZ: 90px;
}
.container {
  /* left: 0; */
  position: absolute;
  /* width: 92px; */
  width: 92px;
  z-index: 3000;
  margin-top: 20px;
  margin-left: -10px;
  /* border: solid black; */
}
.container .ring {
  margin: 75% 0;
  padding: 0;
  width: 92px;
  height: 111px;
  transform-style: preserve-3d;
}
.container .ring > .thumb {
  position: absolute;
  width: 92px;
  height: 106px;
  transform: rotateX(var(--ringspin-rotateX))
    translateZ(var(--ringspin-translateZ));
}

/*
 *************************  Media Queries *************************
  color: gestion de la couleur (en bits/pixel).
  height: hauteur de la zone d'affichage (fenêtre).
  width: largeur de la zone d'affichage (fenêtre).
  device-height: hauteur du périphérique.
  device-width: largeur du périphérique.
  orientation: orientation du périphérique (portrait ou paysage).
  media: type d'écran de sortie. Quelques-unes des valeurs possibles :
    screen: écran « classique » ;
    handheld: périphérique mobile ;
    print: impression ;
    tv: télévision ;
    projection: projecteur ;
    all: tous les types d'écran. 
*/
@media screen and (max-width: 700px) {
}
</style>
